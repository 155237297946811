<template>
	<v-sheet flat>	
		<v-row>		
			<v-col cols="12" sm="12">
				<GeneralForm :id="id" collection="users" :data="user">
					<template v-slot:formContent>
						<UserForm :user="id" :data="user"></UserForm>
					</template>
				</GeneralForm>
			</v-col>
			<v-col cols="12" sm="4">
			</v-col>
		</v-row>
	</v-sheet>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	
	import GeneralForm from "@/components/admin/forms/GeneralForm"
	import UserForm from "@/components/admin/forms/UserForm"

	export default {
		name: "EditUser", 
		props: {
			id: {}
		},
		data: () => {
			return {
				fields: {
					email: "", 
					displayName: "", 
					organisations: []
				}
			}
		},
		computed: {
			user(){
				var user = this.$store.state.users[this.id] 
				return user || this.fields ;
			}
		},
		components: {
			GeneralForm, 
			UserForm, 
		},
		watch: {
			id: {
				immediate: true, 
				handler(){					
					if( this.id ){		
						this.$store.dispatch("users/fetchOne", this.id)
					}
				}
			}
		},
		destroyed(){
			this.$store.dispatch("detachOne", this.id)
		},

	}
// </script>"