<template>
					<v-form>
						<v-row>
							<v-col>
								<v-text-field 	
									:value="data.email" 
									@input="parseEmail"
									:rules="[ v => mwutilities.validateEmail(v) || 'Invalid Email']"
									:label="mwtr.EmailAddress">
								</v-text-field>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<v-text-field 	
									v-model="data.displayName" 
									:label="mwtr.Name">
								</v-text-field>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<UserOrganisations :user="user" :data="data.organisations" @update="(b) => data.organisations = b"></UserOrganisations>
							</v-col>
						</v-row> 
						<v-row v-if="user">
							<v-col>
								<v-select
									chips
									small-chips
									clearable
									deletable-chips
									v-model="data.department"
									append-icon="mdi-plus-circle"
									:items="departmentItems"
									:label="mwtr.DepartmentNavItem"
								></v-select>
							</v-col>
						</v-row> 
					</v-form>
			

</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	import Vuex from "vuex";
	import UserOrganisations from "@/components/admin/panels/UserOrganisations"
	export default {
		name: "UserForm", 
		props: {
			user: {},
			data: {}
		},
		data: () => {
			return {
			
			}
		},
		computed: {
			...Vuex.mapState({
				departments: state => state.departments
			}),
			departmentItems(){
				return this.mwutilities.itemsArray(this.departments)
			}
		},	
		components:{
			UserOrganisations
		},	
		methods: {
			parseEmail( a ){
				this.data.email = a.toLowerCase().trim();		
			}
		},
		created(){
			this.$store.dispatch("departments/fetchAll")			
			this.$store.dispatch("organisations/fetchAll")			
		}


	}
// </script>"