<template>
	<v-autocomplete
	multiple
	v-model="selected"
	append-icon="mdi-plus-circle"
	:items="items"
	:label="mwtr.OrganisationNavItem"
	>
	</v-autocomplete>
</template>

<!-- 	<template v-slot:selection="data">
		<UserAssessmentButton :user="user" :text="data.item.text" :assessment="organisations[data.item.value].assessment"></UserAssessmentButton>
	</template> -->
<style lang="less">
	
</style>

<script type="text/javascript">
	import Vuex from "vuex";
	// import UserAssessmentButton from "@/components/admin/forms/fields/UserAssessmentButton"
	export default {
		name: "UserOrganisations", 
		props: {
			data: {},
			user: {}, 
		},
		data: () => {
			return {
				selected: [], 
			}
		},
		components: {
			// UserAssessmentButton,
			// UserOrganisationAssignments
		},
		computed: {
			...Vuex.mapState({
				userObject(state){
					return state.users[this.user]
				},
				organisations: state => state.organisations
			}),
			items(){
				return this.mwutilities.itemsArray(this.organisations)
			},
			userColor(){
				return "primary"
			}
		},
		methods: {
		},
		watch: {
			selected(nv, ov ){
				const self = this;
				if( ov && nv !== ov ){
					ov.forEach( o => {
						if( nv.indexOf( o ) < 0 ){
							self.$store.dispatch("assessments/deleteUser", 
								{
									user: self.user,
									assessment: self.organisations[o].assessment
								}
							)
						}
					})
				}
				self.$emit("update", nv )
			}, 
			data: {
				immediate: true,
				handler(){
					this.selected = this.data
				}
			}
		},
		created(){
			this.$store.dispatch("organisations/fetchAll")		
			this.$store.dispatch("assessments/fetchAll")			
		}

	}
// </script>"